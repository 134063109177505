/* This file was automatically generated */
:root {
    --color-alabasterApprox: #f8f8f8;
    --color-alabasterSolid: #fafafa;
    --color-alabasterSolid-01: rgba(250, 250, 250, 0.1);
    --color-alto: #dcdcdc;
    --color-alto-00: rgba(220, 220, 220, 0);
    --color-alto-01: rgba(220, 220, 220, 0.1);
    --color-alto-05: rgba(220, 220, 220, 0.5);
    --color-anakiwa: #9bd1fb;
    --color-athensGray: #efeff4;
    --color-azureRadiance: #0396ff;
    --color-bittersweet: #ff6c68;
    --color-black: #000000;
    --color-black-00: rgba(0, 0, 0, 0);
    --color-black-005: rgba(0, 0, 0, 0.05);
    --color-black-01: rgba(0, 0, 0, 0.1);
    --color-black-02: rgba(0, 0, 0, 0.2);
    --color-black-03: rgba(0, 0, 0, 0.3);
    --color-black-05: rgba(0, 0, 0, 0.5);
    --color-black-07: rgba(0, 0, 0, 0.7);
    --color-black-09: rgba(0, 0, 0, 0.9);
    --color-black-015: rgba(0, 0, 0, 0.15);
    --color-black-026: rgba(0, 0, 0, 0.26);
    --color-catalinaBlue: #063773;
    --color-catalinaBlue-02: rgba(6, 55, 115, 0.2);
    --color-catalinaBlue-04: rgba(6, 55, 115, 0.4);
    --color-catalinaBlueWireframe: #084085;
    --color-cinnabar: #ea4336;
    --color-codGray: #1d1d1d;
    --color-concrete: #f2f2f2;
    --color-default: #333333;
    --color-defaultDesktop: #333333;
    --color-denim: #1584d4;
    --color-dodgerBlue: #29d1ff;
    --color-doveGray: #666666;
    --color-dustyGray: #999999;
    --color-endeavour: #005ea8;
    --color-endeavour-01: rgba(0, 94, 168, 0.1);
    --color-foam: #ecf7fd;
    --color-foam-01: rgba(236, 247, 253, 0.1);
    --color-gallery: #ebebeb;
    --color-gamboge: #e29e0a;
    --color-gardenShed: #d4edda;
    --color-goldDrop: #f07c00;
    --color-green: #00ff3c;
    --color-hawkesBlue: #d9effb;
    --color-japaneseLaurel: #008300;
    --color-kashmirBlue: #4a6b91;
    --color-keppel: #3ca7ac;
    --color-lemonTint: #fef3cd;
    --color-lima: #7ab51d;
    --color-lochmara: #0271c2;
    --color-mercury: #e5e5e5;
    --color-milanoRed: #b81900;
    --color-mineShaft: #333333;
    --color-mineShaft-00: rgba(51, 51, 51, 0);
    --color-mineShaft-03: rgba(51, 51, 51, 0.3);
    --color-mineShaft-07: rgba(51, 51, 51, 0.7);
    --color-mineShaft-085: rgba(51, 51, 51, 0.85);
    --color-modeBeige: #96781f;
    --color-monza: #e30613;
    --color-moorMonster: #1c5724;
    --color-mySin: #ffbb1c;
    --color-nobel: #b4b4b4;
    --color-persianRed: #c82d2d;
    --color-petalBloom: #f8d7da;
    --color-prussianBlue: #002646;
    --color-redBerry: #721b25;
    --color-roseOfSharon: #c05702;
    --color-scorpion: #575757;
    --color-selectiveYellow: #f6b800;
    --color-silver: #cccccc;
    --color-stTropaz: #215391;
    --color-sunflower: #ffc618;
    --color-tundora: #444444;
    --color-wasabi: #8a9826;
    --color-white: #ffffff;
    --color-white-00: rgba(255, 255, 255, 0);
    --color-white-01: rgba(255, 255, 255, 0.1);
    --color-white-02: rgba(255, 255, 255, 0.2);
    --color-white-05: rgba(255, 255, 255, 0.5);
    --color-white-06: rgba(255, 255, 255, 0.6);
    --color-white-07: rgba(255, 255, 255, 0.7);
    --color-white-08: rgba(255, 255, 255, 0.8);
    --color-white-09: rgba(255, 255, 255, 0.9);
    --color-white-016: rgba(255, 255, 255, 0.16);
    --color-wildSand: #f4f4f4;
}
