/* This file was automatically generated */
:root {
    --z-index-travelFormInputSearchTooltip: 40001;
    --z-index-travelFormInputOverlayDesktop: 40002;
    --z-index-mapBottomSheet: 40003;
    --z-index-activityNavigation: 40004;
    --z-index-accommodationTabs: 40005;
    --z-index-mapButton: 40006;
    --z-index-wireframeBlockingLayer: 40007;
    --z-index-travelFormCollapsedWrapper: 40008;
    --z-index-cartCta: 40009;
    --z-index-dropdown: 40010;
    --z-index-loadingLayer: 40011;
    --z-index-wireframeMobileHeader: 41001;
    --z-index-wireframeContactLayer: 41002;
    --z-index-wireframeChatty: 41003;
    --z-index-wireframeUliLoginLayer: 41004;
    --z-index-mapTooltipArrow: 42001;
    --z-index-mapTooltipBackdrop: 42002;
    --z-index-notification: 42003;
    --z-index-modalLayer: 42004;
    --z-index-overlayTooltip: 42005;
    --z-index-debugInfoBoxWrapper: 42006;
    --z-index-debugInfoBox: 42007;
    --z-index-hiddenMenu: 42008;
    --z-index-toggleButton: 42009;
    --z-index-reactQueryDevtool: 42010;
    --z-index-wireframeCookieConsentWrapper: 42011;
    --z-index-appSplash: 42012;
}
