@import '@/assets/css/index.scss';

.link {
    display: inline-block;

    &--display-inline {
        display: inline-block;
    }

    &--display-flex {
        display: flex;
    }

    &--fullWidth {
        width: 100%;
    }

    &--fullHeight {
        height: 100%;
    }

    &--highlight {
        @include link-highlight;
    }

    &--isFlex {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    &--disabled {
        color: var(--color-alto);
    }
}
