/* ###############
    Composite Utilities
   ############### */

.button {
    display: flex;
    flex-direction: row;
    column-gap: 10px;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 50px;
    padding: 0 10px;
    border-radius: 3px;
    background-color: var(--color-lochmara);
    font-size: 20px;
    color: var(--color-white);
    line-height: 20px;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    text-decoration: none;
    cursor: pointer;
    appearance: none;
    user-select: none;
}

/* ===============
   Flex Utilities
   =============== */

.flex-center-vertical {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.flex-start-vertical {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.flex-start-center {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.flex-start {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-end {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
}

.flex-start-horizontal {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.flex-start-horizontal-start {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
}

.flex-space-between-horizontal {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.flex-space-between-vertical {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.flex-end-vertical {
    display: flex;
    align-items: flex-end;
}

.justify-end {
    justify-content: flex-end;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-row-reverse {
    display: flex;
    flex-direction: row-reverse;
}

/* ===============
   Gap Utilities
   =============== */

.gap {
    display: flex;
    flex-flow: column;
    flex-wrap: wrap;
    gap: 10px;
    width: 100%;
}

.gap-horizontal {
    display: flex;
    flex-flow: row wrap;
    gap: 10px;
    width: 100%;
}

/* ===============
   Misc Utilities
   =============== */

.full-width {
    width: calc(100% + 20px);
    margin-left: -10px;
}

.area-expansion-after::after {
    content: '';
    position: absolute;
    top: -10px;
    bottom: -10px;
    left: -10px;
    right: -10px;
}

.content-box {
    border-radius: 5px;
    background-color: var(--color-white);
    padding: 10px;
}

.body--desktop .content-box {
    padding: 20px;
    box-shadow: 0 1px 2px var(--color-black-02);
}

.content-box-border {
    border: 1px solid var(--color-alto);
    border-radius: 5px;
    padding: 10px;
}

.body--desktop .content-box-border {
    padding: 20px;
}

.line-through {
    text-decoration: line-through;
}

.icon-color-default {
    filter: brightness(0);
    opacity: 0.825;
}

.ellipsis {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
}

.no-scrollbar {
    scrollbar-color: transparent transparent;
    scrollbar-width: none;
}
.no-scrollbar::-webkit-scrollbar {
    display: none;
}

.empty-backdrop {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
}

.line-clamp-1 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    line-clamp: 1;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    hyphens: auto;
    -webkit-hyphens: auto;
}

.line-clamp-2 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    hyphens: auto;
    -webkit-hyphens: auto;
}

.line-clamp-3 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    line-clamp: 3;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    hyphens: auto;
    -webkit-hyphens: auto;
}

.line-clamp-4 {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    line-clamp: 4;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    hyphens: auto;
    -webkit-hyphens: auto;
}

.bullet {
    font-size: 60%;
}

.bullet::before {
    content: '•';
}

.pipe:not(:first-child)::before {
    content: ' | ';
}

.capitalize {
    text-transform: capitalize;
}

.link {
    color: var(--color-endeavour);
    cursor: pointer;
}

.link:hover {
    color: var(--color-roseOfSharon);
    text-decoration: underline;
}

/* ###############
   Single line / Override Utilities
   ############### */

/* ===============
   Width and Height Utilities
   =============== */

.width-100 {
    width: 100%;
}

.max-width-100 {
    max-width: 100%;
}

.min-width-0 {
    min-width: 0;
}

.min-width-100 {
    min-width: 100%;
}

.min-width-fit-content {
    min-width: fit-content;
}

.height-100 {
    height: 100%;
}

.height-20 {
    height: 20px;
}

.height-0 {
    height: 0;
}

.min-height-100dvh {
    min-height: 100vh;
    min-height: 100dvh;
}

.height-100dvh {
    height: 100vh;
    height: 100dvh;
}

.width-fit-content {
    width: fit-content;
}

.width-max-content {
    width: max-content;
}

.width-auto {
    width: auto;
}
.width-unset {
    width: unset;
}

/* ===============
   Padding Utilities
   =============== */

.padding-0 {
    padding: 0;
}

.padding-2 {
    padding: 2px;
}

.padding-4 {
    padding: 4px;
}

.padding-5 {
    padding: 5px;
}

.padding-6 {
    padding: 6px;
}

.padding-8 {
    padding: 8px;
}

.padding-10 {
    padding: 10px;
}

.padding-15 {
    padding: 15px;
}

.padding-16 {
    padding: 16px;
}

.padding-20 {
    padding: 20px;
}

.padding-30 {
    padding: 30px;
}

.padding-40 {
    padding: 40px;
}

.padding-top-0 {
    padding-top: 0;
}

.padding-top-5 {
    padding-top: 5px;
}

.padding-top-6 {
    padding-top: 6px;
}

.padding-top-10 {
    padding-top: 10px;
}

.padding-top-15 {
    padding-top: 15px;
}

.padding-top-20 {
    padding-top: 20px;
}

.padding-bottom-5 {
    padding-bottom: 5px;
}

.padding-bottom-10 {
    padding-bottom: 10px;
}

.padding-bottom-15 {
    padding-bottom: 15px;
}

.padding-bottom-20 {
    padding-bottom: 20px;
}

.padding-left-0 {
    padding-left: 0;
}

.padding-left-5 {
    padding-left: 5px;
}

.padding-left-8 {
    padding-left: 8px;
}

.padding-left-10 {
    padding-left: 10px;
}

.padding-left-15 {
    padding-left: 15px;
}

.padding-left-20 {
    padding-left: 20px;
}

.padding-right-0 {
    padding-right: 0;
}

.padding-right-5 {
    padding-right: 5px;
}

.padding-right-8 {
    padding-right: 8px;
}

.padding-right-10 {
    padding-right: 10px;
}

.padding-right-15 {
    padding-right: 15px;
}

.padding-right-20 {
    padding-right: 20px;
}

.padding-x-5 {
    padding-left: 5px;
    padding-right: 5px;
}

.padding-x-10 {
    padding-left: 10px;
    padding-right: 10px;
}

.padding-x-15 {
    padding-left: 15px;
    padding-right: 15px;
}
.padding-unset {
    padding: unset;
}

.safe-area-bottom {
    padding-bottom: env(safe-area-inset-bottom, 0);
}

/* ===============
   Margin Utilities
   =============== */
.margin-5 {
    margin: 5px;
}

.margin-10 {
    margin: 10px;
}

.margin-bottom-4 {
    margin-bottom: 4px;
}

.margin-bottom-5 {
    margin-bottom: 5px;
}

.margin-bottom-8 {
    margin-bottom: 8px;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-15 {
    margin-bottom: 15px;
}

.margin-bottom-16 {
    margin-bottom: 16px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-24 {
    margin-bottom: 24px;
}

.margin-bottom-25 {
    margin-bottom: 25px;
}

.margin-bottom-30 {
    margin-bottom: 30px;
}

.margin-left-4 {
    margin-left: 4px;
}

.margin-left-5 {
    margin-left: 5px;
}

.margin-left-10 {
    margin-left: 10px;
}

.margin-right-5 {
    margin-right: 5px;
}

.margin-right-8 {
    margin-right: 8px;
}

.margin-right-10 {
    margin-right: 10px;
}

.margin-right-15 {
    margin-right: 15px;
}

.margin-right-30 {
    margin-right: 30px;
}

.margin-top-0 {
    margin-top: 0;
}

.margin-top-2 {
    margin-top: 2px;
}

.margin-top-5 {
    margin-top: 5px;
}

.margin-top-8 {
    margin-top: 8px;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-12 {
    margin-top: 12px;
}

.margin-top-15 {
    margin-top: 15px;
}

.margin-top-20 {
    margin-top: 20px;
}

.margin-top-25 {
    margin-top: 25px;
}

.margin-top-30 {
    margin-top: 30px;
}

.margin-top--10 {
    margin-top: -10px;
}

.margin-unset {
    margin: unset;
}

/* ===============
   Text Utilities
   =============== */

.text-left {
    text-align: left;
}

.text-end {
    text-align: end;
}

.text-center {
    text-align: center;
}

.text-nowrap {
    white-space: nowrap;
}

.text-spaces {
    white-space: break-spaces;
}

.break-word {
    word-break: break-word;
}

.break-anywhere {
    word-wrap: anywhere;
}

.line-height-12 {
    line-height: 12px;
}

.line-height-14 {
    line-height: 14px;
}

.line-height-16 {
    line-height: 16px;
}

.line-height-17 {
    line-height: 17px;
}

.line-height-18 {
    line-height: 18px;
}

.line-height-19 {
    line-height: 19px;
}

.line-height-22 {
    line-height: 22px;
}

.line-height-24 {
    line-height: 24px;
}

.line-height-25 {
    line-height: 25px;
}

.line-height-32 {
    line-height: 32px;
}

.font-size-10 {
    font-size: 10px;
}

.font-size-11 {
    font-size: 11px;
}

.font-size-12 {
    font-size: 12px;
}

.font-size-13 {
    font-size: 13px;
}

.font-size-14 {
    font-size: 14px;
}

.font-size-15 {
    font-size: 15px;
}

.font-size-16 {
    font-size: 16px;
}

.font-size-18 {
    font-size: 18px;
}

.font-size-20 {
    font-size: 20px;
}

.font-size-22 {
    font-size: 22px;
}

.font-size-24 {
    font-size: 24px;
}

.font-size-32 {
    font-size: 32px;
}

.text-white {
    color: var(--color-white);
}

.text-endeavour {
    color: var(--color-endeavour);
}

.text-persian-red {
    color: var(--color-persianRed);
}

.text-dusty-gray {
    color: var(--color-dustyGray);
}

.text-alto {
    color: var(--color-alto);
}

.text-scorpion {
    color: var(--color-scorpion);
}

.text-dove-gray {
    color: var(--color-doveGray);
}

.text-catalinaBlue {
    color: var(--color-catalinaBlue);
}

.text-lochmara {
    color: var(--color-lochmara);
}

.text-mine-shaft {
    color: var(--color-mineShaft);
}

.text-silver {
    color: var(--color-silver);
}

.text-transparent {
    color: transparent;
}

.font-family-arial {
    font-family: Arial, Helvetica, sans-serif;
}

.bold {
    font-weight: bold;
}

.normal {
    font-weight: normal;
}

.text-shadow {
    text-shadow: 0 0 9px var(--color-black-07);
}

.text-align-right {
    text-align: right;
}

.hyphens-auto {
    hyphens: auto;
    -webkit-hyphens: auto;
}

/* ===============
   Display Utilities
   =============== */

.flex {
    display: flex;
}

.block {
    display: block;
}

.grid {
    display: grid;
}

.inline-block {
    display: inline-block;
}

.inline {
    display: inline;
}

/* ===============
   Grid Utilities
   =============== */

.grid-cols-2 {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.grid-cols-2-1 {
    display: grid;
    grid-template-columns: 2fr 1fr;
}

.grid-cols-3 {
    grid-template-columns: repeat(3, 1fr);
}

.grid-cols-auto-1fr {
    display: grid;
    grid-template-columns: auto 1fr;
}

.grid-gap-10 {
    grid-gap: 10px;
}

/* ===============
   Positioning Utilities
   =============== */

.relative {
    position: relative;
}

.sticky {
    position: -webkit-sticky;
    position: sticky;
}

.absolute {
    position: absolute;
}

.absolute-full {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.fixed {
    position: fixed;
}

.absolute-top {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
}

.top-0 {
    top: 0;
}

.top-10 {
    top: 10px;
}

.top-15 {
    top: 15px;
}

.left-0 {
    left: 0;
}

.left-10 {
    left: 10px;
}

.right-0 {
    right: 0;
}

.right-10 {
    right: 10px;
}

.right-15 {
    right: 15px;
}

.z-index-0 {
    z-index: 0;
}

.z-index-1 {
    z-index: 1;
}

.z-index-2 {
    z-index: 2;
}

.bottom-0 {
    bottom: 0;
}

.bottom-5 {
    bottom: 5px;
}

.bottom-10 {
    bottom: 10px;
}

.bottom-15 {
    bottom: 15px;
}

/* ===============
   Gap Utilities
   =============== */

.column-gap-10 {
    column-gap: 10px;
}

.row-gap-1 {
    row-gap: 1px;
}

.row-gap-5 {
    row-gap: 5px;
}

.row-gap-10 {
    row-gap: 10px;
}

.gap-3 {
    gap: 3px;
}

.gap-4 {
    gap: 4px;
}

.gap-5 {
    gap: 5px;
}

.gap-6 {
    gap: 6px;
}

.gap-7 {
    gap: 7px;
}

.gap-8 {
    gap: 8px;
}

.gap-10 {
    gap: 10px;
}

.gap-12 {
    gap: 12px;
}

.gap-15 {
    gap: 15px;
}

.gap-16 {
    gap: 16px;
}

.gap-20 {
    gap: 20px;
}

.gap-30 {
    gap: 30px;
}

.gap-18 {
    gap: 18px;
}

.gap-24 {
    gap: 24px;
}

.gap-40 {
    gap: 40px;
}

.no-gap {
    gap: 0;
}

.align-center {
    align-items: center;
}

.align-start {
    align-items: flex-start;
}

.align-end {
    align-items: flex-end;
}

.items-start {
    justify-items: flex-start;
}

.justify-start {
    justify-content: flex-start;
}

.justify-center {
    justify-content: center;
}

.justify-space-between {
    justify-content: space-between;
}

.justify-space-evenly {
    justify-content: space-evenly;
}

.wrap {
    flex-wrap: wrap;
}

.nowrap {
    flex-wrap: nowrap;
}

/* ===============
   Flex Alignment Utilities
   =============== */

.align-self-center {
    align-self: center;
}

.align-self-start {
    align-self: start;
}

.align-self-end {
    align-self: flex-end;
}

.shrink-0 {
    flex-shrink: 0;
}

.grow-1 {
    flex-grow: 1;
}

.flex-1 {
    flex: 1;
}

/* ===============
   Background Color Utilities
   =============== */

.bg-transparent {
    background-color: transparent;
}

.bg-white {
    background-color: var(--color-white);
}

.bg-wildSand {
    background-color: var(--color-wildSand);
}

.bg-catalinaBlue {
    background-color: var(--color-catalinaBlue);
}

.bg-mySin {
    background-color: var(--color-mySin);
}

.bg-color-sunflower {
    background-color: var(--color-sunflower);
}

.bg-color-foam {
    background-color: var(--color-foam);
}

.bg-color-catalina-blue {
    background-color: var(--color-catalinaBlue);
}

.bg-color-silver {
    background-color: var(--color-silver);
}

.bg-color-alabasterSolid {
    background-color: var(--color-alabasterSolid);
}

/* ===============
   Border and Border Radius Utilities
   =============== */

.border-none {
    border: none;
}

.border {
    border: 1px solid;
}

.border-transparent {
    border: 1px solid transparent;
}

.border-alto {
    border-color: var(--color-alto);
}

.border-catalinaBlue {
    border-color: var(--color-catalinaBlue);
}

.border-endeavour {
    border-color: var(--color-endeavour);
}

.border-nobel {
    border-color: var(--color-nobel);
}

.border-radius {
    border-radius: 5px;
}

.border-radius-2 {
    border-radius: 2px;
}

.border-radius-3 {
    border-radius: 3px;
}

.border-radius-5 {
    border-radius: 5px;
}

.border-radius-6 {
    border-radius: 6px;
}

.border-radius-7 {
    border-radius: 7px;
}

.border-radius-20 {
    border-radius: 20px;
}

.border-radius-30 {
    border-radius: 30px;
}

.border-radius-circle {
    border-radius: 50%;
}

.border-radius-left {
    border-radius: 5px 0px 0px 5px;
}

.border-radius-right {
    border-radius: 0 5px 5px 0px;
}

.border-radius-top {
    border-radius: 5px 5px 0 0;
}

.border-radius-bottom {
    border-radius: 0 0 5px 5px;
}

.border-left-none {
    border-left: none;
}

.border-top-none {
    border-top: none;
}

/* ===============
   Miscellaneous Utilities
   =============== */

.cursor-default {
    cursor: default;
}

.pointer {
    cursor: pointer;
}

.pointer-none {
    pointer-events: none;
}

.pointer-all {
    pointer-events: all;
}

.pointer-auto {
    pointer-events: auto;
}

.user-select-none {
    user-select: none;
}

.hidden {
    display: none;
}

.invisible {
    visibility: hidden;
}

.opacity-0 {
    opacity: 0;
}

.opacity-50 {
    opacity: 0.5;
}

.opacity-100 {
    opacity: 1;
}

.overflow-auto {
    overflow: auto;
}

.overflow-hidden {
    overflow: hidden;
}

.overflow-y-hidden {
    overflow-y: hidden;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.overflow-x {
    overflow-x: auto;
}

.overflow-x-scroll {
    overflow-x: scroll;
}

.overflow-y-scroll {
    overflow-y: scroll;
}

.overscroll-none {
    overscroll-behavior: none;
}

.vertical-middle {
    vertical-align: middle;
}

.vertical-top {
    vertical-align: top;
}
